const formatPrice = (
  value: number,
  currencyCode: string | null,
  locale?: string
) => {
  try {
    const price = Intl.NumberFormat(locale || 'en-US', {
      style: 'currency',
      currency: currencyCode || 'US',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
    const formattedPrice = locale === 'fr-fr' ? `${price} TTC` : price;
    return formattedPrice;
  } catch (e) {
    return value.toString();
  }
};

export default formatPrice;
