import { Document } from '@contentful/rich-text-types';

import { TDestination } from '@microcopies/sets/destination';

import { Availability } from './availabilityData';

export type VoyageIncludedType = 'Flight' | 'Hotel' | 'Transfer' | 'Other';

export interface Voyage {
  internalName: string;
  sysId: string;
  id: string;
  slug: string;
  bookable: boolean;
  bookingCode: string[];
  name: string;
  title: string;
  titleInEnglish: string;
  subtitle: string;
  longDescriptionTitle: string;
  longDescription: Document | null;
  shortDescription: Document | null;
  destination:
    | {
        sysId: string;
        name: string;
        slug: string;
        showTalkToAnExpert: boolean;
      }[]
    | null;
  highlightedImage: {
    __typename: string;
    url: string;
    caption: string;
    additionalMetadata: string;
  } | null;
  highlightedShipHeader: string;
  gallery: {
    __typename: string;
    title: string;
    images:
      | {
          url: string;
          caption: string;
          additionalMetadata: string;
        }[]
      | null;
  } | null;
  includedNew:
    | {
        title: string;
        icon: string;
        usps: string[];
        type: VoyageIncludedType;
      }[]
    | null;
  notIncluded: Document | null;
  includedFeatures: VoyageIncludedFeature[] | null;
  itinerary: ItineraryDay[] | null;
  map: {
    caption: string;
    additionalMetadata: string;
    url: string;
  } | null;
  ships: FeaturedShip[] | null;
  teamInformation: TeamInformation[] | null;
  usps: string[] | null;
  excursions: Excursion[] | null;
  programs: Program[] | null;
  isAttemptVoyage: boolean;
  attemptText: Document | null;
  seo: {
    name: string;
    title: string;
    description: string;
  } | null;
  availability: Availability | null;
  duration: number | null;
  topics: null;
  localizedSlugs: [TLocale, string][];
}

export interface Excursion {
  __typename: string;
  contentType: string;
  sysId: string;
  description: Document | null;
  name: string;
  introduction: string;
  bookingCode: string;
  price: number;
  slug: string;
  media:
    | {
        title: string;
        description: string;
        url: string;
      }[]
    | null;
}

export interface Program {
  __typename: string;
  contentType: string;
  sysId: string;
  description: Document | null;
  name: string;
  introduction: string;
  bookingCode: string;
  price: number;
  slug: string;
  media:
    | {
        title: string;
        description: string;
        url: string;
      }[]
    | null;
}

export interface FeaturedShip {
  __typename?: string;
  sysId: string;
  name: string;
  aboutShipBlock: Document | null;
  slug: string;
  images:
    | {
        title: string;
        url: string;
      }[]
    | null;
  shipInfo: {
    yearBuilt: number;
    yearOfRefurbishment: number;
    shipYard: string | null;
  };
}

export interface VoyageIncludedFeature {
  id: string;
  __typename: string;
  name: string;
  teaser: string;
  description: Document | null;
  image: {
    url: string;
    caption: string;
    additionalMetadata: string;
  } | null;
}

export interface TeamInformation {
  name: string;
  content: Document | null;
  teamMembers:
    | {
        internalName: string;
        firstName: string;
        lastName: string;
        role: string;
        bio: string;
        actionPhoto: {
          caption: string;
          additionalMetadata: string;
          url: string;
        } | null;
        headshot: {
          caption: string;
          additionalMetadata: string;
          url: string;
        } | null;
      }[]
    | null;
  destination:
    | {
        name: string;
      }[]
    | null;
}

export interface ItineraryDay {
  internalName: string;
  day: string;
  title: string;
  longDescription: Document | null;
  highlightedImage:
    | {
        caption: string;
        additionalMetadata: string;
        url: string;
      }[]
    | null;
  port: {
    name: string;
    locationCoordinates: {
      lat: number;
      lon: number;
    };
  } | null;
  excursions:
    | {
        name: string;
        introduction: string;
        images: { url: string; alt: string }[];
      }[]
    | null;
}

// to keep my sanity
export const retroFitHeroGalleryImages = (gallery: Voyage['gallery']) =>
  (gallery?.images?.map((image) => ({
    cta: undefined,
    description: image.additionalMetadata,
    title: image.caption,
    file: {
      url: image.url
    }
  })) as Contentful.THeroImage[]) ?? [];

export const retroFitAvailability = (availability: Availability | null) => {
  if (!availability) return undefined;
  return {
    ...availability,
    availabilityData: availability.availabilityData
      ? {
          duration: availability.availabilityData.duration ?? undefined,
          voyages:
            availability.availabilityData.voyages?.map((x) => ({
              ...x
            })) ?? []
        }
      : undefined
  } as Contentful.TAvailability | undefined;
};

export const retroFitVoyage = (voyage: Voyage) =>
  ({
    bookable: voyage.bookable ?? undefined,
    duration: voyage.duration ?? undefined,
    bookingCode: voyage.bookingCode ?? [],
    destination: voyage.destination?.map(
      (item) =>
        ({
          ...item
        } as Partial<TDestination>)
    ),
    highlightedImage: {
      internalName: undefined,
      title: voyage.highlightedImage?.caption ?? undefined,
      addionalMetadata: undefined,
      sysId: undefined,
      cta: undefined,
      image: {
        title: voyage.highlightedImage?.caption,
        description: voyage.highlightedImage?.additionalMetadata ?? undefined,
        sysId: undefined,
        file: voyage.highlightedImage?.url
          ? {
              url: voyage.highlightedImage.url
            }
          : undefined
      }
    } as Contentful.TImageWrapper,
    highlightedShipHeader: voyage.highlightedShipHeader ?? undefined,
    images: {
      internalName: '',
      title: voyage.gallery?.title,
      description: '',
      images: voyage.gallery?.images?.map((item) => ({
        image: {
          title: item.caption ?? undefined,
          description: item.additionalMetadata ?? undefined,
          file: item.url
            ? {
                url: item.url
              }
            : undefined
        }
      })) as Contentful.TImageWrapper[],
      contentType: '',
      cta: undefined
    } as Contentful.TImageGallery,
    includedFeatures: undefined,
    internalName: voyage.internalName,
    itinerary:
      (voyage.itinerary?.map((item) => ({
        day: item.day,
        title: item.title,
        internalName: item.internalName ?? undefined,
        longDescription: item.longDescription ?? undefined,
        port: undefined,
        highlightedImage: [],
        availableExcursions: []
      })) as Contentful.TItineraryDay[]) ?? [],
    longDescription: voyage.longDescription,
    map: undefined,
    name: voyage.name,
    notIncluded: voyage.notIncluded,
    shortDescription: voyage.shortDescription,
    slug: voyage.slug,
    sysId: voyage.sysId,
    teamInformation: undefined,
    usps: [],
    title: voyage.title,
    subtitle: voyage.subtitle ?? undefined,
    availability: undefined,
    topics: {
      entries: [
        {
          id: '',
          title: '',
          spaceId: '',
          spaceName: '',
          contentTypeName: ''
        }
      ] as Contentful.TCrossSpaceReference[]
    } as Contentful.TCrossSpaceReferenceWrapper,
    longDescriptionTitle: undefined,
    excursions:
      (voyage.excursions?.map((item) => ({
        contentType: item.contentType,
        introduction: {
          nodeType: 'document',
          content: [],
          data: {}
        } as Document,
        difficulty: '',
        requirements: {
          nodeType: 'document',
          content: [],
          data: {}
        } as Document,
        activityCategory: [],
        duration: '',
        isOnlyBookableOnboard: false,
        description: {
          nodeType: 'document',
          content: [],
          data: {}
        } as Document,
        media:
          (item.media?.map((excursionMedia) => ({
            image: {
              title: excursionMedia.description,
              file: excursionMedia.url
                ? {
                    url: excursionMedia.url,
                    details: null,
                    fileName: '',
                    contentType: ''
                  }
                : undefined
            } as Contentful.TImage
          })) as Contentful.TImageWrapper[]) ?? [],
        name: item.name,
        seasons: [],
        sellingPoints: [],
        currency: 'string',
        internalName: 'string',
        bookingCode: item.bookingCode,
        destinations: [],
        practicalInformation: {
          nodeType: 'document',
          content: [],
          data: {}
        } as Document,
        minimumNumberOfGuests: 'string',
        maximumNumberOfGuests: 'string',
        sysId: item.sysId,
        years: [],
        slug: item.slug,
        price: item.price
      })) as Contentful.TExcursion[]) ?? [],
    programs:
      (voyage.programs?.map((item) => ({
        contentType: item.contentType,
        durationDays: undefined,
        durationHours: undefined,
        introduction: '',
        description: {
          nodeType: 'document',
          content: [],
          data: {}
        } as Document,
        media:
          (item.media?.map((programMedia) => ({
            image: {
              title: programMedia.description,
              file: programMedia.url
                ? {
                    url: programMedia.url,
                    details: null,
                    fileName: '',
                    contentType: ''
                  }
                : undefined
            } as Contentful.TImage
          })) as Contentful.TImageWrapper[]) ?? [],
        name: item.name,
        seasons: [],
        sellingPoints: [],
        currency: 'string',
        internalName: 'string',
        bookingCode: item.bookingCode,
        destinations: [],
        practicalInformation: {
          nodeType: 'document',
          content: [],
          data: {}
        } as Document,
        minimumNumberOfGuests: 'string',
        maximumNumberOfGuests: 'string',
        sysId: item.sysId,
        years: [],
        slug: item.slug,
        price: item.price
      })) as Contentful.TProgram[]) ?? [],
    isAttemptVoyage: voyage.isAttemptVoyage ?? undefined,
    attemptText: undefined,
    seo: undefined
  } as Contentful.TVoyage);
